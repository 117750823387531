/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-handler-names */
import React, { createRef, useContext } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import _debounce from 'lodash/debounce';
import { withAuth0 } from '@auth0/auth0-react';
import { SvgClose } from '@ntuctech/devex-tangram/Icon';
import { ButtonIcon, Grid, ButtonText } from '@ntuctech/devex-tangram';
import nookies from 'nookies';
import { useRouter } from 'next/router';
import MobileNavigation from './MobileNavigation/MobileNavigation';
import HamburgerMenuIcon from '../../icons/HamburgerMenu';
import { RESIZING_TIMER, SCREEN_SIZE, breakpoints } from '../../../lib/Media';
// light
import Group50 from '../../icons/svg/Group50.svg';
import FPG50 from '../../icons/svg/Original.svg';
// Dark
import FPWhite from '../../icons/svg/OriginalDark.svg';
import Group50Dark from '../../icons/svg/Group50-Dark.svg';
import { Cart } from './Cart';
import { CustomiseVoucherContext } from '../../Context/Context';
import { REDIRECT_URL } from '../../../constants';
import { GTM_ENV_VAR as GEV, PAGE_TYPES, SCREEN_NAME } from '../../../lib/eventTrackerFunction';
import { EventTrackingContext } from '../../EventTrackingProvider/EventTrackingProvider';
import Popup from './Popup';
import { setUserType } from '../../../utils/UserType';
import HeaderBusiness from './HeaderBusiness';
import CancelModal from '../../CustomiseVoucher/component/CancelModal';
import { AMP_EVENT_NAME } from '../../../lib/amplitude';
import { getToggleLabel } from '../../../lib/utils';
import { SplitContext } from '../../../SplitContext/splitConfiguration';
import SPLIT_FEATURES from '../../../SplitContext/features';
import { newContactUsUrl } from '../../../lib/navigation';
import useBusinessEntityData from '../../../hooks/useBusinessEntityData';
import useAuthenticationState from '../../../hooks/useAuthenticationState';

const GlobalPopoverStyle = createGlobalStyle`
  .react-tiny-popover-container {
    z-index: 10006; 
  }
`;

const StyledHeaderContainer = styled.div`
  width: 100%;
  max-height: 3.75rem;

  ${SCREEN_SIZE.From.Tablet} {
    max-height: 5rem;
  }
  ${SCREEN_SIZE.Below.MobileL} {
    display: flex;
    max-height: 100%;
  }
`;

const StyledHeaderTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${SCREEN_SIZE.Below.MobileL} {
    width: 100%;
    ${(props) => props.isb2b && 'position: relative;'};
  }
  .center-logo {
    margin: 0 auto !important;
  }
  #hamburger {
    display: none;
  }
  ${SCREEN_SIZE.Below.MobileL} {
    #hamburger {
      display: ${(p) => (p.isPaymentPage ? 'none' : 'block')};
    }
  }
`;

const StyledWrapper = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  background-color: ${(props) => (props.isBusiness ? '#0B2E65' : '#fff')};
  z-index: 9998;
  position: fixed;
  top: 0;
  padding: 0 1.25rem;
  height: 4.5rem;

  ${SCREEN_SIZE.Below.MobileL} {
    height: 4.5rem;
    padding: ${(p) => (p.isPaymentPage ? '1rem 0 1rem 1rem;' : '0.5rem 0.25rem 0.75rem;')};
  }

  ${SCREEN_SIZE.Only.Tablet} {
    padding: 18px 2rem;
  }

  ${SCREEN_SIZE.From.Desktop} {
    padding: 1.25rem 2.5rem;
  }
`;

const HamburgerMenuIconWrapper = styled(HamburgerMenuIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: #696969;
  ${SCREEN_SIZE.Only.Tablet} {
    margin-right: 1.25rem;
  }
  ${SCREEN_SIZE.Below.MobileL} {
    g {
      ${(p) => p.isBusiness && 'fill: #fff !important;'}
    }
    margin: 0.75rem;
  }
`;

const StyledButton = styled(ButtonText)`
  padding: 0 !important;
  margin: 0 !important;
  ${(props) => props.isLogout && !props.isB2b && 'color: #c10b3A'}
  ${(props) => props.haveMargin && 'margin-right: 2rem !important;'};
  ${SCREEN_SIZE.Only.Tablet} {
    ${(props) => props.haveMargin && 'margin-right: 0.5rem !important;'};
  }
  color: ${(props) => (props.isB2b ? '#fff !important;' : '#1454b8;')};
  &:active,
  &:hover,
  &:focus {
    background-color: transparent !important;
    box-shadow: none !important;
    border: 0 !important;
  }
  &:active:before {
    box-shadow: none !important;
  }
  &:focus:before {
    box-shadow: none !important;
  }
`;

const GlobalTopPaddingBody = createGlobalStyle`
  body {
    padding-top: 57px !important;
    background: #F4F5F7 !important;
    overflow: auto;
    height: 100%;
  }
  img {
    width: 100%;
  }
`;

const CartWrp = styled.div``;

const Login = styled.span`
  a {
    font-style: normal;
    line-height: 24px;
    text-decoration: none;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const CloseBtn = styled.span`
  position: relative;
  cursor: pointer;
  svg {
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
    max-width: 1.5rem !important;
    max-height: 1.5rem !important;
    path {
      fill: ${(p) => (p.isB2bEnabled ? '#ffffff' : '#1557BF')};
    }
  }
`;

const BrandIcons = styled.div`
  .no-spacing {
    padding: 0;
    margin: 0;
  }
  .flex {
    display: flex;
    align-items: center;
  }
  display: flex;
  align-items: center;
  img {
    width: 6.625rem !important;
    height: 1.625rem;
    cursor: pointer;
  }
  ${SCREEN_SIZE.From.Tablet} {
    img {
      width: 148px;
    }
  }
  ${SCREEN_SIZE.Below.MobileL} {
    margin-right: auto;
    .max-height {
      height: 1.5rem;
    }
    img {
      width: 5.5rem;
      cursor: pointer;
    }
  }
`;

const StyledImg = styled.img`
  ${SCREEN_SIZE.Below.MobileL} {
    height: 1.375rem;
  }
`;

const StyledHorizontal = styled.div`
  border-left: 1px solid #999999;
  margin: 0 0.75rem;
  height: 1.625rem;
  ${SCREEN_SIZE.Below.MobileL} {
    margin: 0 0.5rem;
  }
`;

const StyledBrandIcons = styled(Grid)`
  padding: 0 !important;
  display: flex;
  height: 2.25rem;
  width: auto;
  ${SCREEN_SIZE.Below.MobileL} {
    display: block;
    height: unset;
  }
`;

const RenderLogin = (props) => {
  const {
    loginWithRedirect,
    destroyeeAllNookies,
    logout,
    cartData,
    isMobile,
    isMyordersEnable,
    router,
    entityData,
  } = props;
  const isEntityUser = entityData?.id;
  const { isB2BSite, isUserAccHasBusinessEntity } = useBusinessEntityData();
  const { isConfirmedLoggedIn } = useAuthenticationState();

  return (
    <>
      <Login>
        {!isConfirmedLoggedIn ? (
          <StyledButton
            size="m"
            isB2b={isB2BSite}
            data-testid="login-button"
            haveMargin={!isB2BSite || (isB2BSite && isUserAccHasBusinessEntity)}
            onClick={() => {
              nookies.set({}, REDIRECT_URL, window.location.href);
              loginWithRedirect({ redirectUri: window.location.origin });
            }}
          >
            Log in/Sign up
          </StyledButton>
        ) : (
          <>
            {isB2BSite && isMyordersEnable && (
              <>
                <StyledButton
                  size="m"
                  isB2b={isB2BSite}
                  haveMargin
                  data-testid="contact-us-button"
                  onClick={() => {
                    window.open(newContactUsUrl, '_blank');
                  }}
                >
                  Contact us
                </StyledButton>
                {isEntityUser && (
                  <StyledButton
                    size="m"
                    isB2b={isB2BSite}
                    haveMargin
                    data-testid="my-orders"
                    onClick={() => router?.push('/my-orders')}
                  >
                    My orders
                  </StyledButton>
                )}
              </>
            )}
            <StyledButton
              size="m"
              isB2b={isB2BSite}
              haveMargin={!isB2BSite || (isB2BSite && isUserAccHasBusinessEntity)}
              data-testid="logout-button"
              onClick={() => {
                destroyeeAllNookies();
                logout({
                  returnTo: window.location.origin,
                });
              }}
            >
              Log out
            </StyledButton>
          </>
        )}
      </Login>

      {!isB2BSite || (isB2BSite && isUserAccHasBusinessEntity) ? (
        <Cart
          deliveryModeB2B={isB2BSite}
          isMobile={isMobile}
          {...props}
          count={cartData.length}
          query={SCREEN_SIZE.From.Tablet}
        />
      ) : null}
    </>
  );
};

class PageHeaderVariantB extends React.Component {
  constructor(props) {
    super(props);
    this.ref = createRef();

    this.state = {
      isMobileNavOpen: false,
      isOpen: false,
      isModalOpen: false,
      userTypeSelection: '',
    };

    this.handleCloseMobileNav = this.handleCloseMobileNav.bind(this);
    this.handleOpenMobileNav = this.handleOpenMobileNav.bind(this);
    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.handleNavigationClick = this.handleNavigationClick.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  handleCloseClick(track, windowVar) {
    if (['/customise-voucher/'].includes(windowVar.location?.pathname)) {
      track({
        [GEV.PAGE_TYPE]: PAGE_TYPES.VOUCHER,
        [GEV.SCREEN_NAME]: SCREEN_NAME.CHECKOUT,
        [GEV.EVENT_CAT]: 'Egifting',
        [GEV.EVENT_ACTION]: 'click',
        [GEV.EVENT_LABEL]: 'gifting_checkout_back',
      });
    }
  }

  handleOpenMobileNav() {
    this.setState({ isMobileNavOpen: true });
  }

  handleCloseMobileNav() {
    this.setState({ isMobileNavOpen: false });
  }

  handleNavigationClick(deliveryModeB2B, isMobile, track, index) {
    track({
      [GEV.AMPLITUDE_EVENT_NAME]: AMP_EVENT_NAME.TOGGLE_POPUP_VIEWED,
      [GEV.MEMBER_TYPE]: deliveryModeB2B ? 'B2B' : 'B2C',
      [GEV.AMPLITUDE_VERTICAL]: AMP_EVENT_NAME.VERTICAL,
      [GEV.AMPLITUDE_CTA_LOCATION]: `egifting | ${getToggleLabel()}`,
      [GEV.AMPLITUDE_CTA_SUB_LOCATION]: 'widget | egifting portal',
    });
    const windowVar = typeof window !== 'undefined' && window;
    if (
      ['/customise-voucher', '/cart', '/my-orders', '/my-orders/details'].includes(
        window.location.pathname
      )
    ) {
      this.setState({ isModalOpen: true });
      this.setState({ userTypeSelection: deliveryModeB2B ? 'B2C' : 'B2B' });
    } else if (isMobile) {
      setUserType(index === 0 ? 'B2C' : 'B2B', false);
      windowVar.location.href = '/';
    } else {
      this.setState({ isOpen: true });
    }
  }

  render() {
    const { isMobileNavOpen, isOpen, isModalOpen, userTypeSelection } = this.state;
    const { router, track, splits } = this.props;

    const {
      isEditMode,
      cartData,
      destroyeeAllNookies,
      setIsEditMode,
      isB2bEnabled: deliveryModeB2B,
      entityData,
    } = this.context;
    const {
      auth0: { loginWithRedirect, logout },
      isMobile,
    } = this.props;
    const windowVar = typeof window !== 'undefined' && window;
    /* eslint-disable no-nested-ternary */
    const isPaymentPage = ['/payment/', '/confirmation/'].includes(windowVar?.location?.pathname);
    const isBusinessFeatureEnable = splits?.[SPLIT_FEATURES.GIFTING_BUSINESS]?.treatment === 'on';
    const isMyordersEnable = splits?.[SPLIT_FEATURES.MY_ORDERS]?.treatment === 'on';
    const label = userTypeSelection === 'B2B' ? 'Business' : 'Personal';

    return (
      <>
        <GlobalPopoverStyle />
        <GlobalTopPaddingBody />
        <CancelModal
          open={isModalOpen}
          onClose={() => this.setState({ isModalOpen: false })}
          isSwitching
          userTypeSelection={userTypeSelection}
          onSwitchClick={() => {
            this.setState({ isOpen: false });
            windowVar.location.href = '/';
            setUserType(userTypeSelection, false);
          }}
          deliveryModeB2B={deliveryModeB2B}
          track={track}
        />
        <StyledWrapper
          isBusiness={deliveryModeB2B}
          ref={this.ref}
          data-testid="page-header-navigation"
          isPaymentPage={isPaymentPage}
        >
          <Popup
            open={isOpen}
            onClose={() => this.setState({ isOpen: false })}
            deliveryModeB2B={deliveryModeB2B}
            onSelection={(selected) => {
              track({
                [GEV.AMPLITUDE_EVENT_NAME]: AMP_EVENT_NAME.TOGGLE_POPUP_CLICK,
                [GEV.MEMBER_TYPE]: deliveryModeB2B ? 'B2B' : 'B2C',
                [GEV.AMPLITUDE_VERTICAL]: AMP_EVENT_NAME.VERTICAL,
                [GEV.AMPLITUDE_CTA_LOCATION]: 'egifting | home',
                [GEV.AMPLITUDE_CTA_SUB_LOCATION]: 'widget | egifting portal',
                [GEV.AMPLITUDE_GIFTING_MESSAGE]: `switch to ${label}`,
              });
              setUserType(selected, true);
              this.setState({ isOpen: false });
              const url = new URL(window.location.href);
              if (selected === 'B2B') {
                url.searchParams.set('type', 'business');
                window.location.href = url;
              } else {
                url.searchParams.delete('type');
                window.location.href = url;
              }
            }}
          />
          <StyledHeaderContainer id="styledHeader">
            <StyledHeaderTop isb2b={deliveryModeB2B} isPaymentPage={isPaymentPage}>
              {!isEditMode ? (
                <div id="hamburger">
                  <HamburgerMenuIconWrapper
                    isBusiness={deliveryModeB2B}
                    onClick={this.handleOpenMobileNav}
                    data-testid="open-mobilenav-button"
                  />
                  <MobileNavigation
                    handleClose={this.handleCloseMobileNav}
                    navShow={isMobileNavOpen}
                    router={router}
                    destroyeeAllNookies={destroyeeAllNookies}
                    data-testid="close-mobilenav-button"
                    deliveryModeB2B={deliveryModeB2B}
                    isMyordersEnable={isMyordersEnable}
                    entityData={entityData}
                    handleNavigationClick={(index) =>
                      this.handleNavigationClick(deliveryModeB2B, true, track, index)
                    }
                  />
                </div>
              ) : null}

              <BrandIcons data-testid="fairPriceLogo" isBusiness={deliveryModeB2B}>
                <StyledBrandIcons>
                  <Grid className="no-spacing flex max-height">
                    <StyledImg
                      onClick={() => {
                        router?.push('/');
                        window.location.href = '/';
                      }}
                      alt=""
                      src={deliveryModeB2B ? Group50Dark.src : Group50.src}
                      data-testid="home-screen-btn"
                    />

                    <StyledHorizontal />

                    <StyledImg
                      onClick={() => {
                        router?.push('/');
                        window.location.href = '/';
                      }}
                      alt=""
                      src={deliveryModeB2B ? FPWhite.src : FPG50.src}
                      data-testid="home-screen-btn-white"
                    />
                    {!['/payment/', '/confirmation/'].includes(windowVar?.location?.pathname) &&
                      isBusinessFeatureEnable && (
                        <HeaderBusiness
                          isMobile={isMobile}
                          deliveryModeB2B={deliveryModeB2B}
                          handlerOnClick={() => {
                            this.handleNavigationClick(deliveryModeB2B, false, track);
                          }}
                        />
                      )}
                  </Grid>
                </StyledBrandIcons>
              </BrandIcons>
              {windowVar?.location?.pathname === '/payment/' ||
              windowVar?.location?.pathname === '/confirmation/' ||
              isEditMode === true ? (
                <>
                  <CloseBtn isB2bEnabled={deliveryModeB2B}>
                    <ButtonIcon
                      onClick={() => {
                        router?.push('/cart/');
                        window.location.href = '/cart/';
                        setIsEditMode(false);
                        this.handleCloseClick(track, windowVar);
                      }}
                    >
                      <SvgClose data-testid="svgclose" size="m" />
                    </ButtonIcon>
                  </CloseBtn>
                </>
              ) : (
                <CartWrp data-testid="cart-wrap">
                  <RenderLogin
                    loginWithRedirect={loginWithRedirect}
                    destroyeeAllNookies={destroyeeAllNookies}
                    logout={logout}
                    cartData={cartData || []}
                    entityData={entityData}
                    isMobile={isMobile}
                    isMyordersEnable={isMyordersEnable}
                    {...this.props}
                  />
                </CartWrp>
              )}
            </StyledHeaderTop>
          </StyledHeaderContainer>
        </StyledWrapper>
      </>
    );
  }
}

const PageHeaderVariantBWrapper = (props) => {
  const router = useRouter();
  const splitConfig = useContext(SplitContext);
  const { splits } = splitConfig;
  const [isMobile, setIsMobile] = React.useState(false);
  React.useEffect(() => {
    const updateWindowDimensions = () => {
      setIsMobile(window.innerWidth < breakpoints.tablet);
    };

    window.addEventListener('resize', _debounce(updateWindowDimensions, RESIZING_TIMER));

    updateWindowDimensions();

    return () =>
      window.removeEventListener('resize', _debounce(updateWindowDimensions, RESIZING_TIMER));
  }, []);

  return (
    <EventTrackingContext.Consumer>
      {({ track }) => (
        <PageHeaderVariantB
          {...props}
          isMobile={isMobile}
          splits={splits}
          router={router}
          track={track}
        />
      )}
    </EventTrackingContext.Consumer>
  );
};
PageHeaderVariantB.contextType = CustomiseVoucherContext;

export default withAuth0(PageHeaderVariantBWrapper);
