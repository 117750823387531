/* eslint-disable react/jsx-handler-names */
import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { ButtonText, Typography, Grid } from '@ntuctech/devex-tangram';
import nookies from 'nookies';
import { useAuth0 } from '@auth0/auth0-react';
import {
  MOBILE_NAVIGATION_B2B,
  MOBILE_NAVIGATION_B2C,
  MOBILE_NAVIGATION_B2B_NO_ENTITY,
  NON_LOGIN_B2B,
} from '../../../../lib/navigation';
import { SCREEN_SIZE } from '../../../../lib/Media';
import MobileNavCloseIcon from '../../../icons/MobileNavClose';
import { StyledPopup } from '../../../Popup/StyledPopup';
import { disablePageScroll } from '../../../../lib/utils';
import { REDIRECT_URL } from '../../../../constants';
import useAuthenticationState from '../../../../hooks/useAuthenticationState';
import { CustomiseVoucherContext } from '../../../Context/Context';

const StyledNavContainer = styled.nav`
  .sub-menu {
    margin-left: 1.5rem;
  }
  width: 80%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  background: #fff;
  position: fixed;
  z-index: 10057;
  top: 0;
  .no-spacing {
    padding: 0;
    margin: 0;
  }
  left: ${(props) => (props.show ? '0' : '-80%')};
  transition: left 0.5s ease;
  ${SCREEN_SIZE.Only.Desktop} {
    display: none;
  }
  ${SCREEN_SIZE.From.Tablet} {
    width: 45%;
    left: ${(props) => (props.show ? '0' : '-45%')};
  }
`;

const StyledNavContent = styled.div`
  height: 100%;
  padding: 1rem 1rem 0 1rem;
  box-sizing: border-box;
`;

const StyledNavClose = styled(MobileNavCloseIcon)`
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  margin-bottom: 0.5rem;
`;

const NewLabelBox = styled.div`
  padding: 0.0625rem 0.125rem;
  margin-left: 0.25rem;
  background-color: #ff8833;
  border-radius: 0.125rem;
  display: inline-block;
  line-height: 0;
`;

const StyledButtonText = styled(ButtonText)`
  padding: 0 !important;
  margin: 0 !important;
  min-width: unset !important;
  display: block;
  text-align: left;
  height: 1.5rem;

  &:hover,
  &:active,
  &:focus {
    background-color: unset !important;
    box-shadow: none;
    span {
      color: #1454b8;
    }
  }
  &:active:before {
    box-shadow: none;
  }

  ${({ isActive }) =>
    isActive &&
    `
    color: #1557bf;
    border-left: 4px solid #1557bf;
    margin-left: -16px;
    padding-left: 12px;

    svg {
      color: #1557bf;
    }
  `}

  span {
    ${({ active }) => active && 'color: #1454b8; font-weight: 700; margin: 0; padding: 0;'};
  }

  ${NewLabelBox} {
    transform: translateY(-0.2rem);
  }

  ${({ hasAlert }) =>
    hasAlert &&
    css`
      :after {
        content: '';
        display: inline-block;
        height: 0.5rem;
        width: 0.5rem;
        background-color: #c10b3a;
        border-radius: 50%;
        transform: translateY(-0.6rem);
      }
    `}
`;

const StyledUl = styled.ul`
  margin-top: 0;
  height: 100%;
  li {
    margin: 0.75rem 0;
  }
`;

const StyledNavDivider = styled.div`
  height: 0;
  color: #eaeaea;
  padding: 0;
  border: 0.063rem solid #eaeaea;
`;

const StyledNavItem = styled.li`
  cursor: pointer;
`;

function RenderLoginLogoutLink({ activeIndex, setActiveIndex }) {
  const { isConfirmedLoggedIn } = useAuthenticationState();
  const { logout, loginWithRedirect } = useAuth0();
  const { destroyeeAllNookies } = useContext(CustomiseVoucherContext);

  return (
    <StyledNavItem>
      {!isConfirmedLoggedIn ? (
        <StyledButtonText
          size="s"
          active={activeIndex === 5}
          onClick={() => {
            nookies.set({}, REDIRECT_URL, window.location.href);
            loginWithRedirect({ redirectUri: window.location.origin });
            setActiveIndex(5);
          }}
          data-testid="loginButton"
        >
          <Typography variant="bodyM">Log in/Sign Up</Typography>
        </StyledButtonText>
      ) : (
        <StyledButtonText
          size="s"
          active={activeIndex === 6}
          onClick={() => {
            destroyeeAllNookies();
            logout({ returnTo: window.location.origin });
            setActiveIndex(6);
          }}
          data-testid="logoutButton"
        >
          <Typography variant="bodyM">Logout</Typography>
        </StyledButtonText>
      )}
    </StyledNavItem>
  );
}

class MobileNavigationInner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeNavPath: undefined,
      activeNavLabel: undefined,
      activeIndex: 0,
    };

    this.componentRef = React.createRef();
    this.renderNav = this.renderNav.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
  }

  componentDidMount() {
    const { navShow, router, deliveryModeB2B } = this.props;
    disablePageScroll(navShow);
    window.addEventListener('click', this.handleTouchEnd, false);
    this.setState({ activeIndex: deliveryModeB2B ? 1 : 0 });

    if (router) {
      const currentPath = router?.pathname?.split('/')[1];
      const secondaryMatchers = {
        categoryDetails: 'Categories',
        categories: 'Categories',
        category: 'Categories',
        payment: 'Payment',
      };
      this.setState({
        activeNavPath: currentPath,
        activeNavLabel: secondaryMatchers[currentPath],
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { navShow, deliveryModeB2B } = this.props;
    if (prevProps.deliveryModeB2B !== deliveryModeB2B) {
      this.setState({ activeIndex: deliveryModeB2B ? 1 : 0 });
    }
    disablePageScroll(navShow);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleTouchEnd, false);
  }

  handleTouchEnd = (e) => {
    const { handleClose } = this.props;
    if (e && e.target.dataset?.testid?.toString().includes('mobileNavOverlay')) {
      e.stopPropagation();
      disablePageScroll(false);
      handleClose();
    }
  };

  onHomeScreenHandler(index) {
    const { router } = this.props;
    this.setState({ activeIndex: index });

    router?.push('/');
  }

  renderNav(navList, isMyordersEnable) {
    const { hasAlert, handleNavigationClick, router } = this.props;
    const { activeNavPath, activeNavLabel, activeIndex } = this.state;
    return navList?.map((link, index) => {
      const { label, url, subMenu, isNewTab, subMenuLink } = link;
      const labelToDisplay = label;
      let isAccountChild = false;

      isAccountChild =
        typeof window !== 'undefined' &&
        window.location.hash?.toUpperCase().includes(label?.toUpperCase());

      const isActive =
        isAccountChild ||
        (activeNavPath && activeNavPath === url?.split('/')[1] && window.location.hash === '') ||
        (activeNavLabel && activeNavLabel === labelToDisplay);

      const active = index === activeIndex;
      const isMyOrderActive = `/${activeNavPath}/` === '/my-orders/';

      return (
        <>
          {url || isNewTab ? (
            <li key={labelToDisplay} data-name={labelToDisplay} data-testid="nav-item">
              <StyledButtonText
                size="s"
                hasAlert={hasAlert}
                isActive={isActive}
                data-testid={label}
                active={active}
                passHref
                onClick={() => {
                  this.setState({ activeIndex: index });
                  // eslint-disable-next-line no-unused-expressions
                  isNewTab ? this.onHomeScreenHandler(index) : window.open(url, '_blank');
                }}
              >
                <Typography variant="bodyM">{labelToDisplay}</Typography>
              </StyledButtonText>
            </li>
          ) : (
            <li key={labelToDisplay} data-name={labelToDisplay} data-testid="nav-item">
              <StyledButtonText
                size="s"
                onClick={() => {
                  this.setState({ activeIndex: index });
                  /* eslint-disable no-unused-expressions */
                  !subMenu ? handleNavigationClick(index) : this.onHomeScreenHandler(index);
                }}
                active={active && !isMyOrderActive}
                hasAlert={hasAlert}
                isActive={isActive}
                data-testid={label}
              >
                <Typography variant={active ? 'bodyMBold' : 'bodyM'}>{labelToDisplay}</Typography>
              </StyledButtonText>
            </li>
          )}
          {index === 0 && <StyledNavDivider />}

          {subMenu && isMyordersEnable && (
            <li
              key={labelToDisplay}
              className="sub-menu"
              data-name={labelToDisplay}
              data-testid="nav-item"
            >
              <Grid className="no-spacing">
                <StyledButtonText
                  size="s"
                  data-testid={subMenu}
                  active={isMyOrderActive}
                  onClick={() => {
                    this.setState({ activeIndex: subMenu });
                    router?.push(subMenuLink);
                  }}
                >
                  <Typography variant={activeIndex === subMenu ? 'bodyMBold' : 'bodyM'}>
                    {subMenu}
                  </Typography>
                </StyledButtonText>
              </Grid>
            </li>
          )}
        </>
      );
    });
  }

  render() {
    const { navShow, handleClose, isLoggedIn, deliveryModeB2B, isMyordersEnable, entityData } =
      this.props;
    const isEntityUser = entityData?.id;

    const getNavList = () => {
      if (isLoggedIn && deliveryModeB2B) {
        const slicedMenu = isEntityUser ? MOBILE_NAVIGATION_B2B : MOBILE_NAVIGATION_B2B_NO_ENTITY;
        return slicedMenu;
      }
      if (!isLoggedIn && deliveryModeB2B) {
        return NON_LOGIN_B2B;
      }
      return MOBILE_NAVIGATION_B2C;
    };
    const navigationList = getNavList();
    const { activeIndex } = this.state;

    return (
      <>
        {navShow && disablePageScroll(true)}
        {navShow && <StyledPopup data-testid="mobileNavOverlay" />}
        <StyledNavContainer ref={this.componentRef} show={navShow}>
          <StyledNavContent>
            <StyledNavClose data-testid="close-mobilenav" onClick={handleClose} />
            <StyledUl>
              {this.renderNav(navigationList, isMyordersEnable)}
              <>
                <StyledNavDivider isLogout />
                <RenderLoginLogoutLink
                  activeIndex={activeIndex}
                  setActiveIndex={(idx) => {
                    this.setState({ activeIndex: idx });
                  }}
                />
              </>
            </StyledUl>
          </StyledNavContent>
        </StyledNavContainer>
      </>
    );
  }
}

const MobileNavigation = (props) => {
  return <MobileNavigationInner {...props} />;
};

export default MobileNavigation;
