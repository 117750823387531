import { useContext } from 'react';
import { CustomiseVoucherContext } from '../components/Context/Context';
import useAuthenticationState from './useAuthenticationState';

const useBusinessEntityData = () => {
  const { isB2bEnabled: isB2BSite, entityData } = useContext(CustomiseVoucherContext);
  const { isConfirmedNotLoggedIn } = useAuthenticationState();
  const isEntityDataReady = isConfirmedNotLoggedIn || entityData !== null;
  const isUserAccHasBusinessEntity = isEntityDataReady && !!entityData?.id;

  return {
    isB2BSite,
    isEntityDataReady,
    isUserAccHasBusinessEntity,
    entityData,
  };
};

export default useBusinessEntityData;
