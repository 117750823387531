import React from 'react';
import {
  Modal,
  ModalContent,
  ModalActions,
  Typography,
  ModalTitle,
  ButtonContained,
  NdsThemeProvider,
  ButtonText,
} from '@ntuctech/devex-tangram';
import styled from 'styled-components';
import { GTM_ENV_VAR as GEV } from '../../../lib/eventTrackerFunction';
import { AMP_EVENT_NAME } from '../../../lib/amplitude';
import { getToggleLabel } from '../../../lib/utils';

const StyledModalActions = styled(ModalActions)`
  margin-top: 1.5rem;
`;

const StyledModalContent = styled(ModalContent)`
  margin-top: 0.75rem;
`;

const CancelModal = ({
  open,
  onClose,
  setIsOkay,
  isSwitching,
  onSwitchClick,
  userTypeSelection,
  track = () => {},
  deliveryModeB2B,
}) => {
  const label = userTypeSelection === 'B2B' ? 'Business' : 'Personal';
  return (
    <NdsThemeProvider>
      <Modal open={open}>
        <ModalTitle>
          <Typography variant="titleM">
            {isSwitching ? `Switch to ${label} E-Gift Vouchers?` : 'Cancel this order?'}
          </Typography>
        </ModalTitle>
        <StyledModalContent>
          <Typography variant="bodyL">Your progress on this page will not be saved.</Typography>
        </StyledModalContent>
        <StyledModalActions>
          <ButtonText data-testid="onclose-btn" size="m" onClick={() => onClose()}>
            Close
          </ButtonText>
          <ButtonContained
            data-testid="yes-btn"
            color="destructive"
            size="m"
            onClick={() => {
              track({
                [GEV.AMPLITUDE_EVENT_NAME]: AMP_EVENT_NAME.TOGGLE_POPUP_CLICK,
                [GEV.MEMBER_TYPE]: deliveryModeB2B ? 'B2B' : 'B2C',
                [GEV.AMPLITUDE_VERTICAL]: AMP_EVENT_NAME.VERTICAL,
                [GEV.AMPLITUDE_CTA_LOCATION]: `egifting | ${getToggleLabel()}`,
                [GEV.AMPLITUDE_CTA_SUB_LOCATION]: 'widget | egifting portal',
                [GEV.AMPLITUDE_GIFTING_MESSAGE]: isSwitching ? `switch to ${label}` : label,
              });
              if (isSwitching) {
                onSwitchClick();
              } else {
                setIsOkay(true);
              }
            }}
          >
            {isSwitching ? `Switch to ${label}` : 'Yes, cancel'}
          </ButtonContained>
        </StyledModalActions>
      </Modal>
    </NdsThemeProvider>
  );
};
export default CancelModal;
