import React from 'react';
import { css } from 'styled-components';
import { createBreakpoints } from 'styled-breakpoints';
import _debounce from 'lodash/debounce';

export const breakpoints = {
  // tl;dr Avoid using `from('mobile')` and only use `only('mobile')` as an escape hatch to avoid
  // unnecessary unsetting of styles in a bigger viewport.

  // All CSS here are usually implemented in a mobile-first fashion. Which means that there is
  // really no point in using `from('mobile')` because everything is assumed to for mobile
  // viewport unless scoped with media queries.
  mobile: 1,
  mobileL: 600,
  tablet: 768,
  desktop: 1200,
  hd: 1366,
  uhd: 1440,
  uhdL: 1600,
};

const { above: from, below, only } = createBreakpoints(breakpoints);

export const RESIZING_TIMER = 500;

const SCREEN_SIZE_NAMES = {
  Mobile: 'mobile',
  MobileL: 'mobileL',
  Tablet: 'tablet',
  Desktop: 'desktop',
  Hd: 'hd',
  Uhd: 'uhd',
  UhdL: 'uhdL',
};

// should NOT make it dynamic and should keep it stable for reference as well as autocompletion
// UHD is possible to apply for "from" merely
export const SCREEN_SIZE = {
  From: {
    MobileL: from(SCREEN_SIZE_NAMES.MobileL),
    Tablet: from(SCREEN_SIZE_NAMES.Tablet),
    Desktop: from(SCREEN_SIZE_NAMES.Desktop),
    Hd: from(SCREEN_SIZE_NAMES.Hd),
    Uhd: from(SCREEN_SIZE_NAMES.Uhd),
    UhdL: from(SCREEN_SIZE_NAMES.UhdL),
  },
  Only: {
    Mobile: only(SCREEN_SIZE_NAMES.Mobile),
    MobileL: only(SCREEN_SIZE_NAMES.MobileL),
    Tablet: only(SCREEN_SIZE_NAMES.Tablet),
    Desktop: only(SCREEN_SIZE_NAMES.Desktop),
    Hd: only(SCREEN_SIZE_NAMES.Hd),
  },
  Below: {
    Mobile: below(SCREEN_SIZE_NAMES.Mobile),
    MobileL: below(SCREEN_SIZE_NAMES.MobileL),
    Tablet: below(SCREEN_SIZE_NAMES.Tablet),
    Desktop: below(SCREEN_SIZE_NAMES.Desktop),
    Hd: below(SCREEN_SIZE_NAMES.Hd),
  },
};

export const BREAKPOINT_CHECKS = {
  From: {
    MobileL: (windowWidth) => windowWidth >= breakpoints.mobileL,
    Tablet: (windowWidth) => windowWidth >= breakpoints.tablet,
    Desktop: (windowWidth) => windowWidth >= breakpoints.desktop,
    Hd: (windowWidth) => windowWidth >= breakpoints.hd,
    Uhd: (windowWidth) => windowWidth >= breakpoints.uhd,
    UhdL: (windowWidth) => windowWidth >= breakpoints.uhdL,
  },
  Only: {
    Mobile: (windowWidth) => windowWidth < breakpoints.mobileL,
    MobileL: (windowWidth) =>
      windowWidth >= breakpoints.mobileL && windowWidth < breakpoints.tablet,
    Tablet: (windowWidth) => windowWidth >= breakpoints.tablet && windowWidth < breakpoints.desktop,
    Desktop: (windowWidth) => windowWidth >= breakpoints.desktop && windowWidth < breakpoints.hd,
    Hd: (windowWidth) => windowWidth >= breakpoints.hd && windowWidth < breakpoints.uhd,
  },
  Below: {
    Mobile: (windowWidth) => windowWidth <= breakpoints.mobile,
    MobileL: (windowWidth) => windowWidth <= breakpoints.mobileL,
    Tablet: (windowWidth) => windowWidth <= breakpoints.tablet,
    Desktop: (windowWidth) => windowWidth <= breakpoints.desktop,
    Hd: (windowWidth) => windowWidth <= breakpoints.hd,
  },
};

const findOptionAndDeviceFromQuery = (query) => {
  let currentOptionKey = '';
  let currentDeviceKey = '';
  let isMatched = false;
  Object.keys(SCREEN_SIZE).forEach((optionKey) => {
    const currentOption = SCREEN_SIZE[optionKey];
    Object.keys(currentOption).forEach((deviceKey) => {
      const queryValue = currentOption[deviceKey];
      if (queryValue === query) {
        isMatched = true;
        currentDeviceKey = deviceKey;
      }
    });
    if (isMatched) {
      currentOptionKey = optionKey;
    }
  });
  return {
    optionKey: currentOptionKey,
    deviceKey: currentDeviceKey,
  };
};

export const visible = (query) =>
  query === SCREEN_SIZE.Only.Mobile
    ? css`
        display: block;

        ${SCREEN_SIZE.From.Tablet} {
          display: none;
        }
      `
    : css`
        display: none;

        ${query} {
          display: block;
        }
      `;

export class Media extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isRendered: true };
  }

  componentDidMount() {
    this.processDisplay();
    window.addEventListener('resize', _debounce(this.processDisplay, RESIZING_TIMER));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', _debounce(this.processDisplay, RESIZING_TIMER));
  }

  processDisplay = () => {
    const { query } = this.props;
    if (query) {
      const { optionKey, deviceKey } = findOptionAndDeviceFromQuery(query);
      this.setState({
        isRendered: BREAKPOINT_CHECKS[optionKey][deviceKey](window.innerWidth),
        // windowWidth: window.innerWidth,
      });
    }
  };

  render() {
    // no query prop means returning whatever is containing in children
    const { query, children } = this.props;
    const { isRendered } = this.state;
    if (!query) {
      return children;
    }
    if (isRendered) {
      /* eslint-disable react/no-unknown-property */
      return (
        <div css={visible(query)} {...this.props}>
          {children}
        </div>
      );
    }

    return null;
  }
}

export const withMedia = (WrappedComponent) => {
  return class Component extends React.PureComponent {
    render() {
      const { query } = this.props;
      return (
        <Media query={query}>
          <div>
            <WrappedComponent {...this.props} />
          </div>
        </Media>
      );
    }
  };
};

export default visible;
